import Decider from "../components/decider/decider";
import Links from "../components/links/link";
import LoginPage from "../components/login/login";

export const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/links",
    element: <Links />,
  },
  {
    path: "*",
    element: <Decider />,
  },
];
