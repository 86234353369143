import { useMemo } from "react";

const LinkPagination = (props) => {
  const { links, setPage, page } = props;
  const pageNumbers = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= links.allPage; i++) {
      pages.push(i);
    }
    return pages;
  }, [links.allPage]);

  const paginate = (pageNumber) => setPage(pageNumber);

  let displayedPageNumbers = [];
  if (pageNumbers.length > 4) {
    if (page === 1) {
      displayedPageNumbers = [
        pageNumbers[0],
        pageNumbers[page],
        pageNumbers[page + 1],
        "...",
        pageNumbers[pageNumbers.length - 1],
      ];
    } else if (page === pageNumbers.length) {
      displayedPageNumbers = [
        pageNumbers[0],
        "...",
        pageNumbers[page - 3],
        pageNumbers[page - 2],
        pageNumbers[page - 1],
      ];
    } else {
      if (page === pageNumbers[1]) {
        displayedPageNumbers = [
          pageNumbers[0],
          pageNumbers[page - 1],
          pageNumbers[page],
          "...",
          pageNumbers[pageNumbers.length - 1],
        ];
      } else if (page === pageNumbers[2]) {
        displayedPageNumbers = [
          pageNumbers[0],
          pageNumbers[page - 2],
          pageNumbers[page - 1],
          pageNumbers[page],
          "...",
          pageNumbers[pageNumbers.length - 1],
        ];
      } else if (page === pageNumbers[pageNumbers.length - 2]) {
        displayedPageNumbers = [
          pageNumbers[0],
          "...",
          pageNumbers[page - 2],
          pageNumbers[page - 1],
          pageNumbers[page],
        ];
      } else if (page === pageNumbers[pageNumbers.length - 3]) {
        displayedPageNumbers = [
          pageNumbers[0],
          "...",
          pageNumbers[page - 2],
          pageNumbers[page - 1],
          pageNumbers[page],
          pageNumbers[page + 1],
        ];
      } else {
        displayedPageNumbers = [
          pageNumbers[0],
          "...",
          pageNumbers[page - 2],
          pageNumbers[page - 1],
          pageNumbers[page],
          "...",
          pageNumbers[pageNumbers.length - 1],
        ];
      }
    }
  } else {
    displayedPageNumbers = pageNumbers;
  }

  return (
    <div>
      <nav>
        <ul className="pagination">
          {displayedPageNumbers.map((number, index) => (
            <li key={index} className="page-item">
              {number === "..." ? (
                <button className="page-link"> {number}</button>
              ) : (
                <button
                  onClick={() => paginate(number)}
                  className={`page-link ${page === number ? "active" : ""}`}
                >
                  {number}
                </button>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default LinkPagination;
