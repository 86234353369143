import LinkButton from "../atoms/linkButton";
import LinkInput from "../atoms/linkInput";
const LinkSearchBar = (props) => {
  const { setSearchName, searchName, setModalVisible } = props;
  return (
    <div className="linkSearchLocation">
      <LinkInput setSearchName={setSearchName} searchName={searchName} />

      <LinkButton
        children={"+"}
        func={()=>setModalVisible(true)}
        className={"linkAddButton"}
      />
    </div>
  );
};
export default LinkSearchBar;
