import axios from "axios";
import { LINK_ACTIONS } from "../constants/actionTypes";
import { TEXTS } from "../constants/text";
export const getAllLink = (page, name) => {
  return async (dispatch) => {
    dispatch({ type: LINK_ACTIONS.LINKS_LOADING });
    await axios
      .get(TEXTS.LINK_ENDPOINT, { params: { page: page, name: name } })
      .then((response) => {
        dispatch({
          type: LINK_ACTIONS.LINKS_LOADED,
          payload: response.data.links,
        });
      })
      .catch((err) => {
        dispatch({
          type: LINK_ACTIONS.LINKS_ERROR,
          payload: err.response?.data,
        });
        console.log(err);
      });
  };
};

export const createLink = (params) => {
  return async (dispatch) => {
    dispatch({ type: LINK_ACTIONS.LINK_REGISTRATION_START });
    await axios
      .post(TEXTS.LINK_ENDPOINT, params, {})
      .then((response) => {
        dispatch({
          type: LINK_ACTIONS.LINK_REGISTRATION_SUCCESS,
          payload: response.data.link,
        });
      })
      .catch((err) => {
        dispatch({
          type: LINK_ACTIONS.LINK_REGISTRATION_ERROR,
          payload: err,
        });
        console.log(err);
      });
  };
};

export const modifyLink = (params) => {
  return async (dispatch) => {
    dispatch({ type: LINK_ACTIONS.LINK_REGISTRATION_START });
    await axios
      .put(TEXTS.LINK_ENDPOINT, params, {})
      .then((response) => {
        dispatch({
          type: LINK_ACTIONS.LINK_REGISTRATION_SUCCESS,
          payload: response.data.link,
        });
      })
      .catch((err) => {
        dispatch({
          type: LINK_ACTIONS.LINK_REGISTRATION_ERROR,
          payload: err,
        });
        console.log(err);
      });
  };
};

export const deleteLink = (id) => {
  return async (dispatch) => {
    dispatch({ type: LINK_ACTIONS.LINK_REGISTRATION_START });
    await axios
      .delete(`${TEXTS.LINK_ENDPOINT}/${id}`)
      .then(() => {
        dispatch({
          type: LINK_ACTIONS.LINK_REGISTRATION_SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};
