import { useEffect, useState } from "react";
import { TEXTS } from "../text";

export default function CharacterDisplay(props) {
  const { min, taken } = props;
  const [count, setCount] = useState(min);
  useEffect(() => {
    if (taken) {
      const counter = min - taken;
      setCount(counter);
    }
    if (taken === 0) {
      setCount(min);
    }
  }, [min, taken]);
  return <div> {`${TEXTS.CHARACTER_LENGTH} ${count}`}</div>;
}
