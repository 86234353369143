import React from "react";
import CharacterDisplay from "../../../constants/characterDisplay/characterDisplay";
function ModalInput(props) {
  const { labelText, inputName, value, maxLength, onChange, min } = props;
  return (
    <div>
      <div className="modalRowDisplays">
        <label className="modalLabelDisplay">{labelText}:</label>
        <input
          maxLength={maxLength}
          className="modalInputDisplay"
          type="text"
          name={inputName}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      <div className="characterDisplay">
        <CharacterDisplay
          min={min}
          taken={value?.length}
        />
      </div>
    </div>
  );
}

export default ModalInput;
