import { useCallback } from "react";
import { LINK_ACTIONS } from "../../constants/actionTypes";

export const useNameChange = (dispatch) => {
  const handleNameChange = useCallback(
    (value) => {
      dispatch({ type: LINK_ACTIONS.SET_NAME, name: value });
    },
    [dispatch]
  );

  return handleNameChange;
};

export const useDownloadUrlChange = (dispatch) => {
  const handleUrlChange = useCallback(
    (value) => {
      dispatch({ type: LINK_ACTIONS.SET_DOWNLOAD_URL, downloadUrl: value });
    },
    [dispatch]
  );

  return handleUrlChange;
};

export const useGoogleUrlChange = (dispatch) => {
  const handleGooglePlayUrlChange = useCallback(
    (value) => {
      dispatch({
        type: LINK_ACTIONS.SET_GOOGLE_PLAY_URL,
        googlePlayUrl: value,
      });
    },
    [dispatch]
  );

  return handleGooglePlayUrlChange;
};

export const useAppleUrlChange = (dispatch) => {
  const handleApplePlayUrlChange = useCallback(
    (value) => {
      dispatch({
        type: LINK_ACTIONS.SET_APPLE_STORE_URL,
        appleStoreUrl: value,
      });
    },
    [dispatch]
  );

  return handleApplePlayUrlChange;
};

export const useDesktopUrlChange = (dispatch) => {
  const handleDesktopUrlChange = useCallback(
    (value) => {
      dispatch({
        type: LINK_ACTIONS.SET_PC_DESKTOP_URL,
        pcDesktopUrl: value,
      });
    },
    [dispatch]
  );

  return handleDesktopUrlChange;
};

export const useSelectedIdChange = (dispatch) => {
  const handleSelectedIdChange = useCallback(
    (value) => {
      dispatch({
        type: LINK_ACTIONS.SET_SELECTED_ID,
        selectedId: value,
      });
    },
    [dispatch]
  );

  return handleSelectedIdChange;
};

export const useSearchNameChange = (dispatch) => {
  const handleSearchNameChange = useCallback(
    (value) => {
      dispatch({ type: LINK_ACTIONS.SET_SEARCH_NAME, searchName: value });
    },
    [dispatch]
  );

  return handleSearchNameChange;
};

export const useMessageChange = (dispatch) => {
  const handleMessageChange = useCallback(
    (value) => {
      dispatch({ type: LINK_ACTIONS.SET_MESSAGE, errorMessage: value });
    },
    [dispatch]
  );

  return handleMessageChange;
};
