export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
  SET_EMAIL: "SET_EMAIL",
  SET_PASSWORD: "SET_PASSWORD",
};

export const LINK_ACTIONS = {
  LINKS_LOADING: "LINKS_LOADING",
  LINKS_LOADED: "LINKS_LOADED",
  LINKS_ERROR: "LINKS_ERROR",
  LINK_LOADING: "LINK_LOADING",
  LINK_LOADED: "LINK_LOADED",
  LINK_ERROR: "LINK_ERROR",
  LINK_REGISTRATION_START: "LINK_REGISTRATION_START",
  LINK_REGISTRATION_SUCCESS: "LINK_REGISTRATION_SUCCESS",
  LINK_REGISTRATION_ERROR: "LINK_REGISTRATION_ERROR",
  SET_NAME: "SET_NAME",
  SET_DOWNLOAD_URL: "SET_DOWNLOAD_URL",
  SET_GOOGLE_PLAY_URL: "SET_GOOGLE_PLAY_URL",
  SET_APPLE_STORE_URL: "SET_APPLE_STORE_URL",
  SET_PC_DESKTOP_URL: "SET_PC_DESKTOP_URL",
  SET_SELECTED_ID: "SET_SELECTED_ID",
  SET_SEARCH_NAME: "SET_SEARCH_NAME",
  SET_MESSAGE: "SET_MESSAGE",
};
