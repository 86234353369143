import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "../reducer/login";
import { LOGIN_ACTIONS } from "../constants/actionTypes";
import linkReducer from "../reducer/link";
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["linkReducer"],
};

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state.linkReducer = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  loginReducer,
  linkReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
