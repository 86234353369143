import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createLink, deleteLink, modifyLink } from "../../../actions/link";
import PopupStyle from "../../../constants/popup/popup";
import { TEXTS } from "../../../constants/text";
import deleteLogo from "../../../static/deleteLogo.png";
import ModalButton from "./modalButton";
import ModalHeader from "./modalHeader";
import ModalInput from "./modalInput";

const LinkModal = (props) => {
  const {
    modalVisible,
    modify,
    name,
    handleNameChange,
    googlePlayUrl,
    handleGooglePlayUrlChange,
    appleStoreUrl,
    handleAppleStoreUrlChange,
    pcDesktopUrl,
    handlePcDesktopUrlChange,
    handleCloseModal,
    selectedId,
  } = props;
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [disableButton, setDisableButton] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleCreateLink = () => {
    const params = {
      name: name,
      googlePlayLink: googlePlayUrl ? googlePlayUrl : null,
      appleStoreLink: appleStoreUrl ? appleStoreUrl : null,
      pcDesktopLink: pcDesktopUrl ? pcDesktopUrl : null,
    };
    dispatch(createLink(params));
  };

  const handleModifyLink = () => {
    const params = {
      id: selectedId,
      name: name,
      googlePlayLink: googlePlayUrl,
      appleStoreLink: appleStoreUrl,
      pcDesktopLink: pcDesktopUrl,
    };
    setIsPopupVisible(false);
    dispatch(modifyLink(params));
  };

  const handleDeleteLink = () => {
    dispatch(deleteLink(selectedId));
    setIsPopupVisible(false);
    handleCloseModal();
  };

  useEffect(() => {
    modalRef.current.style.transition = "opacity 0.3s ease-out";
  }, []);

  const toggleModal = () => {
    modalRef.current.style.opacity = modalVisible ? 1 : 0;
  };

  const onCancelClick = () => {
    handleCloseModal();
    setIsPopupVisible(false);
  };

  useEffect(toggleModal, [modalVisible]);

  useEffect(() => {
    if (
      name === "" ||
      (googlePlayUrl === "" && appleStoreUrl === "" && pcDesktopUrl === "")
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [name, googlePlayUrl, appleStoreUrl, pcDesktopUrl]);

  return (
    <div ref={modalRef} className="modal">
      {modalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <ModalHeader
              modify={modify}
              deleteLogo={deleteLogo}
              onClickDelete={setIsPopupVisible}
            />
            <div>
              <ModalInput
                labelText={TEXTS.MODAL_PROJECT_NAME}
                maxLength={255 - process.env.REACT_APP_BASE_URL?.length}
                inputName="name"
                value={name}
                onChange={handleNameChange}
                min={255 - process.env.REACT_APP_BASE_URL?.length}
              />
              <ModalInput
                labelText={TEXTS.MODAL_GOOGLE_PLAY}
                maxLength={247}
                inputName="googlePlayUrl"
                value={googlePlayUrl}
                onChange={handleGooglePlayUrlChange}
                min={247}
              />
              <ModalInput
                labelText={TEXTS.MODAL_APP_STORE}
                maxLength={247}
                inputName="appleStoreUrl"
                value={appleStoreUrl}
                onChange={handleAppleStoreUrlChange}
                min={247}
              />
              <ModalInput
                labelText={TEXTS.MODAL_PC_DESKTOP}
                maxLength={247}
                inputName="pcDesktopUrl"
                value={pcDesktopUrl}
                onChange={handlePcDesktopUrlChange}
                min={247}
              />
            </div>
            <ModalButton
              onClick={onCancelClick}
              disabled={disableButton}
              text={TEXTS.CANCEL}
              cancel={true}
            />
            <ModalButton
              onClick={modify ? handleModifyLink : handleCreateLink}
              disabled={disableButton}
              text={TEXTS.SAVE}
              cancel={false}
            />
          </div>
        </div>
      )}
      <PopupStyle
        isPopupVisible={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
        message={TEXTS.POPUP_DELETE_LINK_TEXT}
        submitButton={handleDeleteLink}
        stopAutoClose={true}
      />
    </div>
  );
};

export default LinkModal;
