import React from "react";
import { TEXTS } from "../../../constants/text";

function ModalHeader(props) {
  const { modify, deleteLogo, onClickDelete } = props;
  const modalName = modify ? TEXTS.MODIFY_MODAL_NAME : TEXTS.CREATE_MODAL_NAME;

  return (
    <div className="modalHeaderFix">
      <div className="linkAddProjectTitle">{modalName}</div>
      {modify && (
        <button className="modalDleteButton" onClick={onClickDelete}>
          <img className="deleteLogo" src={deleteLogo} alt="deleteLogo" />
        </button>
      )}
    </div>
  );
}

export default ModalHeader;
