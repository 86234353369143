import { LOGIN_ACTIONS } from "../constants/actionTypes";

export const loginInitialState = {
  token: null,
  user: {},
  message: {},
  status: false,
  email: "",
  password: "",
};

const loginReducer = (state = loginInitialState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.TOKEN_LOADING:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: "",
        status: false,
      });
    case LOGIN_ACTIONS.TOKEN_LOADED:
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        message: "",
        status: true,
      });
    case LOGIN_ACTIONS.TOKEN_ERROR:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: action.payload,
        status: false,
      });
    case LOGIN_ACTIONS.LOGOUT:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: "",
        status: false,
      });
    case LOGIN_ACTIONS.SET_EMAIL:
      return { ...state, email: action.email };
    case LOGIN_ACTIONS.SET_PASSWORD:
      return { ...state, password: action.password };
    default:
      return state;
  }
};

export default loginReducer;
