import useWindowDimensions from "../windowsize/windowsize";

export default function TuncateString(string) {
  const { width } = useWindowDimensions();
  if (string?.length > 50) {
    if (width < 1480) {
      return string.substring(0, 30) + "...";
    }
    return string.substring(0, 50) + "...";
  }
  return string;
}
