import axios from "axios";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./constants/routes";
import { TEXTS } from "./constants/text";

function App() {
  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
  return (
    <Suspense fallback={<div>{TEXTS.LOADING}</div>}>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Router>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
