import React from "react";

function ModalButton(props) {
  const { text, disabled, onClick, cancel } = props;

  return (
    <button
      className={cancel ? "linkCancelButton" : "linkCreateButton"}
      disabled={cancel ? false : disabled}
      type="submit"
      onClick={onClick}
    >
      <div className="buttonSpace">{text}</div>
    </button>
  );
}

export default ModalButton;
