import { useEffect, useRef } from "react";
import { TEXTS } from "../text";
import "./style.css";

const PopupStyle = (props) => {
  const {
    isPopupVisible,
    setIsPopupVisible,
    message,
    submitButton,
    stopAutoClose,
  } = props;
  const popupRef = useRef(null);

  useEffect(() => {
    popupRef.current.style.transition = "opacity 0.2s ease-out";
  }, []);

  const toggleModal = () => {
    popupRef.current.style.opacity = isPopupVisible ? 1 : 0;
  };

  useEffect(toggleModal, [isPopupVisible]);

  useEffect(() => {
    if (isPopupVisible && !stopAutoClose) {
      setTimeout(() => {
        setIsPopupVisible(false);
      }, 1200);
    }
  }, [isPopupVisible, setIsPopupVisible, stopAutoClose]);

  return (
    <div ref={popupRef}>
      {isPopupVisible && (
        <div>
          <div className="popup">
            <p className="message">
              {message}
              {submitButton && (
                <div>
                  <button
                    className="popupButton"
                    onClick={() => setIsPopupVisible(false)}
                  >
                    {TEXTS.NO}
                  </button>
                  <button className="popupButton" onClick={submitButton}>
                    {TEXTS.YES}
                  </button>
                </div>
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupStyle;
