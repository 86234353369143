import { useCallback } from "react";
import { LOGIN_ACTIONS } from "../../constants/actionTypes";

export const useEmailChange = (dispatch) => {
  const handleEmailChange = useCallback(
    (value) => {
      dispatch({ type: LOGIN_ACTIONS.SET_EMAIL, email: value });
    },
    [dispatch]
  );

  return handleEmailChange;
};

export const usePasswordChange = (dispatch) => {
  const handlePasswordChange = useCallback(
    (value) => {
      dispatch({ type: LOGIN_ACTIONS.SET_PASSWORD, password: value });
    },
    [dispatch]
  );

  return handlePasswordChange;
};
