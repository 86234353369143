import { LINK_ACTIONS } from "../constants/actionTypes";

export const linkInitialState = {
  link: {},
  links: [],
  message: {},
  status: false,
  saveStatus: false,
  name: "",
  downloadUrl: "",
  googlePlayUrl: "",
  appleStoreUrl: "",
  pcDesktopUrl: "",
  selectedId: null,
  searchName: "",
  errorMessage: "",
};

const linkReducer = (state = linkInitialState, action) => {
  switch (action.type) {
    case LINK_ACTIONS.LINKS_LOADING:
      return Object.assign({}, state, {
        links: [],
        message: "",
        status: false,
      });
    case LINK_ACTIONS.LINKS_LOADED:
      return Object.assign({}, state, {
        links: action.payload,
        message: "",
        status: true,
      });
    case LINK_ACTIONS.LINKS_ERROR:
      return Object.assign({}, state, {
        links: [],
        message: action.payload?.message,
        status: false,
      });
    case LINK_ACTIONS.LINK_LOADING:
      return Object.assign({}, state, {
        link: {},
        message: "",
        status: false,
      });
    case LINK_ACTIONS.LINK_LOADED:
      return Object.assign({}, state, {
        link: action.payload,
        message: "",
        status: true,
      });
    case LINK_ACTIONS.LINK_ERROR:
      return Object.assign({}, state, {
        link: {},
        message: action.payload?.message,
        status: false,
      });
    case LINK_ACTIONS.LINK_REGISTRATION_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case LINK_ACTIONS.LINK_REGISTRATION_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case LINK_ACTIONS.LINK_REGISTRATION_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload.response?.data,
      });
    case LINK_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case LINK_ACTIONS.SET_DOWNLOAD_URL:
      return { ...state, downloadUrl: action.downloadUrl };
    case LINK_ACTIONS.SET_GOOGLE_PLAY_URL:
      return { ...state, googlePlayUrl: action.googlePlayUrl };
    case LINK_ACTIONS.SET_APPLE_STORE_URL:
      return { ...state, appleStoreUrl: action.appleStoreUrl };
    case LINK_ACTIONS.SET_PC_DESKTOP_URL:
      return { ...state, pcDesktopUrl: action.pcDesktopUrl };
    case LINK_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    case LINK_ACTIONS.SET_SEARCH_NAME:
      return { ...state, searchName: action.searchName };
    case LINK_ACTIONS.SET_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };
    default:
      return state;
  }
};

export default linkReducer;
