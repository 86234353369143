import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../actions/login";
import loginReducer, { loginInitialState } from "../../reducer/login";
import "./style.css";
import logo from "../../static/appentumlogo.png";
import PopupStyle from "../../constants/popup/popup";
import { TEXTS } from "../../constants/text";
import { useEmailChange, usePasswordChange } from "./loginUtils";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(new Date());
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [state, reducerDispatch] = useReducer(loginReducer, loginInitialState);
  const status = useSelector((state) => state.loginReducer.status);
  const message = useSelector((state) => state.loginReducer.message);
  const { email, password } = state;

  const handleEmailChange = useEmailChange(reducerDispatch);
  const handlePasswordChange = usePasswordChange(reducerDispatch);

  const handleLogin = () => {
    const params = {
      email: email,
      password: password,
    };
    dispatch(login(params));
  };

  useEffect(() => {
    if (status) {
      navigate("/links");
    }
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (message?.code === 1002 || message?.code === 1003) {
        if (message?.data) {
          setTimer(
            Math.floor(
              (new Date(message?.data).getTime() - new Date().getTime()) / 1000
            )
          );
        }
        setIsPopupVisible(true);
      }
    }, 500);
  }, [status, message, navigate, timer]);

  return (
    <div>
      <div className="loginField">
        <div>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <div className="loginPageTitle">{TEXTS.LOGIN_TITLE}</div>
          <div>
            <input
              className="loginInput"
              placeholder={TEXTS.LOGIN_EMAIL_PLACEHOLDER}
              value={email}
              type="text"
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </div>
          <div>
            <input
              className="loginInput"
              placeholder={TEXTS.LOGIN_PASSWORD_PLACEHOLDER}
              value={password}
              type="password"
              onChange={(e) => handlePasswordChange(e.target.value)}
            />
          </div>
          <button
            className="loginSubmitButton"
            type="submit"
            onClick={handleLogin}
          >
            {TEXTS.LOGIN}
          </button>
        </div>
      </div>
      <PopupStyle
        message={
          message?.code === 1003
            ? `${TEXTS.TOO_MANY_LOGIN_ATTEMPTS} ${timer <= 0 ? 0 : timer}`
            : TEXTS.LOGIN_POPUP_ERROR_MESSAGE
        }
        isPopupVisible={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
      />
    </div>
  );
}
