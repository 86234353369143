import { TEXTS } from "../../../../constants/text";
import Button from "../atoms/tableButton";
import Image from "../atoms/tableImage";

const QRCode = (props) => {
  const { qrCode, linkName, onDownload } = props;
  return (
    <div>
      <Image src={qrCode} alt="QR Code" className={"linkQRcode"} />
      <div>
        <Button onClick={() => onDownload(qrCode, linkName)}>
          {TEXTS.DOWNLOAD}
        </Button>
      </div>
    </div>
  );
};
export default QRCode;
