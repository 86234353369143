import { Link } from "react-router-dom";
import { TEXTS } from "../../constants/text";
import "./style.css";
export default function NotFoundPage() {
  return (
    <div className="container">
      <h1 className="header">404</h1>
      <p className="text">{TEXTS.SITE_NOT_FOUND_TITLE}</p>
      <Link to="/" className="link">
        {TEXTS.SITE_NOT_FOUND_LINK}
      </Link>
    </div>
  );
}
