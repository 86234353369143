import { TEXTS } from "../../../constants/text";

const LinkInput = (props) => {
  const { setSearchName, searchName } = props;
  return (
    <input
      className="linkSearchInput"
      placeholder={TEXTS.SEARCH_INPUT_PLACEHOLDER}
      onChange={(e) => setSearchName(e.target.value)}
      value={searchName}
    />
  );
};
export default LinkInput;
