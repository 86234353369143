const TableHeader = (props) => {
  const { children, width } = props;
  return (
    <th style={{ width: width }}>
      <div className="linkTableMargin">{children}</div>
    </th>
  );
};

export default TableHeader;
