import axios from "axios";
import { LOGIN_ACTIONS } from "../constants/actionTypes";
import { TEXTS } from "../constants/text";
import HttpService from "../services/http";
export const login = (params) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING });
    await axios
      .post(TEXTS.LOGIN_ENDPOINT, params, {})
      .then((response) => {
        const tokenToSave = `Bearer ${response.data.token.accessToken}`;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response?.data,
        });
        console.log(err);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.LOGOUT });
  };
};
