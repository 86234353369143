import LinkButton from "../atoms/linkButton";
import LinkImage from "../atoms/linkImage";
import logo from "../../../static/appentumlogo.png";
import { TEXTS } from "../../../constants/text";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/login";
import { useNavigate } from "react-router-dom";
const LinkHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className="linkHeader">
      <LinkImage className={"linkHeaderLogo"} src={logo} alt={logo} />
      <div className="linkLogoutLocation">
        <LinkButton
          className={"linkLogoutButton"}
          children={TEXTS.LOGOUT}
          func={handleLogout}
        />
      </div>
    </div>
  );
};
export default LinkHeader;
