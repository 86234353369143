import { TEXTS } from "../../../../constants/text";
import TuncateString from "../../../../constants/truncateString/truncateString";
import Button from "../atoms/tableButton";
import TableData from "../atoms/tableData";
import Image from "../atoms/tableImage";
import Text from "../atoms/tableText";
import QRCode from "./qrCode";
import copyLogo from "../../../../static/copyLogo.png";
import editLogo from "../../../../static/editLogo.png";

const LinkRow = (props) => {
  const { link, onCopy, onEdit, onDownload } = props;
  return (
    <tr key={link.id}>
      <TableData width="20em">
        <Text>{link.name}</Text>
      </TableData>
      <TableData width="10em">
        <QRCode qrCode={link.qrUrl} linkName={link.name} onDownload={onDownload} />
      </TableData>
      <TableData width="25em">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text>{TuncateString(link.downloadLink)}</Text>
          <Button onClick={() => onCopy(link.downloadLink)}>
            <Image src={copyLogo} alt="downloadlink" />
          </Button>
        </div>
      </TableData>
      <TableData width="40em">
        <div>
          <div className="linkApplink">{TEXTS.GOOGLE_PLAY_URL}</div>
          <div className="truncatedLink">
            {TuncateString(link.googlePlayLink)}
          </div>
        </div>
        <div>
          <div className="linkApplink">{TEXTS.APPLE_STORE_URL}</div>
          <div className="truncatedLink">
            {TuncateString(link.appleStoreLink)}
          </div>
        </div>
        <div>
          <div className="linkApplink">{TEXTS.PC_DESKTOP_URL}</div>
          <div className="truncatedLink">
            {TuncateString(link.pcDesktopLink)}
          </div>
        </div>
      </TableData>
      <TableData width="5em">
        <div>
          <Button onClick={() => onCopy(link.googlePlayLink)}>
            <Image
              src={copyLogo}
              alt="copyLogo"
              className={"linkCopyButtonStyle"}
            />
          </Button>
          <br />
          <Button onClick={() => onCopy(link.appleStoreLink)}>
            <Image
              src={copyLogo}
              alt="copyLogo"
              className={"linkCopyButtonStyle"}
            />
          </Button>
          <br />
          <Button onClick={() => onCopy(link.pcDesktopLink)}>
            <Image
              src={copyLogo}
              alt="copyLogo"
              className={"linkCopyButtonStyle"}
            />
          </Button>
        </div>
      </TableData>
      <TableData width="5em">
        <div>
          <Button className="linkEditButtonStyle" onClick={() => onEdit(link)}>
            <Image
              className="linkEditIconStyle"
              src={editLogo}
              alt="editLogo"
            />
          </Button>
        </div>
      </TableData>
    </tr>
  );
};
export default LinkRow;
