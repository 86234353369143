import axios from "axios";
import { useEffect, useState } from "react";
import { TEXTS } from "../../constants/text";
import NotFoundPage from "../not-found-site/site-not-found";

export default function Decider() {
  const [pageNotFound, setPageNotFound] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const fetch = async () => {
      await axios
        .get(`${TEXTS.LINK_ENDPOINT}/routing`, {
          params: { url: window.location.href },
        })
        .then((response) => {
          let url = response.data.pc;
          if (userAgent.match(/iPhone|iPad|iPod/i)) {
            url = response.data.ios;
          } else if (userAgent.match(/Android/i)) {
            url = response.data.android;
          }
          if (url !== null) {
            setRedirectUrl(url);
          } else {
            setPageNotFound(true);
          }
        })
        .catch((err) => {
          setPageNotFound(true);
          console.log(err);
        });
    };
    fetch();
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
      return null;
    }
  }, [redirectUrl]);

  return <div>{pageNotFound && <NotFoundPage />}</div>;
}
